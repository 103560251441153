import { CommonContainer, CommonTextContainer, LightSection } from '../../styles/common.style';
import { CustomList, DataContainer, DescriptionContainer, ItemContainer } from './style';
import { LayoutName } from '../../components/LayoutName';
import React from 'react';

const data: TData[] = [
    {
        left: 'Диагностика форсунок BOSCH DENSO DELPHI',
        right: 'от 400 ₽'
    },
    {
        left: 'Ремонт форсунок BOSCH DENSO DELPHI',
        right: 'от 1500 ₽'
    },
    {
        left: 'Ремонт ТНВД',
        right: 'от 4500 ₽'
    }
];

type TData = {
    left: string;
    right: string;
};

export const Price = () => {
    return (
        <LightSection>
            <CommonContainer>
                <CommonTextContainer>
                    <LayoutName color="black">Стоимость ремонта</LayoutName>
                </CommonTextContainer>
                <DataContainer>
                    <DescriptionContainer>
                        <h3>
                            Стоимость ремонта форсунок Common Rail и ТНВД в Челябинске
                        </h3>
                        <span>
                            Технический центр по обслуживанию дизельной техники «Место Дизеля»
                            оказывает услуги по проверке и ремонту форсунок Common Rail BOSCH, DENSO, DELPHI в Челябинске.
                        </span>
                        <span>
                            Также специалисты выполнят диагностику и программирование дизельных систем транспортного средства.
                        </span>
                        <h3>
                            Гарантия
                        </h3>
                        <span>
                            Мы уверены в своих сотрудниках, поэтому всегда даем гарантию на все работы и запчасти, которые были приобретены у нас.
                        </span>
                        <span>
                            Гарантия составляет 3 месяца при соблюдении условий эксплуатации и выполнения гарантийных обязательств.
                            Промывка топливного бака, замена фильтра (оригинал), промывка топливной рампы.
                        </span>
                    </DescriptionContainer>
                    <CustomList
                        size="large"
                        bordered
                        dataSource={ data }
                        renderItem={
                            (item: TData) =>
                                <ItemContainer>
                                    <span>{ item.left }</span>
                                    <span>{ item.right }</span>
                                </ItemContainer>
                        }
                    />
                </DataContainer>
            </CommonContainer>
        </LightSection>
    );
};