import { Modal } from 'antd';
import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const Container = styled(Modal)`
    .ant-modal-close {
        height: auto;
        width: auto;
    }
`;

export const ContentContainer = styled.form`
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    min-width: 450px;

    .ant-input {
        resize: none;

        &:hover, &:focus {
            box-shadow: none;
            border-color: ${ COLORS.primary };
        }
    }


`;