import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const Container = styled.footer`
    background: ${ COLORS.dark_gradient };
    display: flex;
    padding-block: 60px;
    gap: 10px;
    padding-inline: clamp(10px, 7vw, 150px);
    justify-content: space-between;
`;

export const LeftFooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;

    span {
        color: ${ COLORS.white };
        margin-bottom: 0;
    }

    a {
        color: ${ COLORS.white };
        margin-bottom: 0;
    }

    a:hover {
        color: ${ COLORS.primary };
        margin-bottom: 0;
        transition: 0.3s;
    }
`;

export const RightFooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 24px;
    flex: none;
    order: 2;
    flex-grow: 0;

    h3 {
        font-weight: 400;
        line-height: 130%;
        color: ${ COLORS.white };
    }

    @media (orientation: portrait) {
        display: none;
    }
`;

export const SocialMedia = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;