import React from 'react';

import { Header } from '../layouts/Header';
import { Footer } from '../layouts/Footer';
import { Preview } from '../layouts/Preview';
import { Price } from '../layouts/Price';
import { ServiceMap } from '../layouts/Map';
import { Contact } from '../layouts/Contact';

const ServicePage = () => (
    <>
        <Header />
        <Preview />
        <Price />
        <Contact />
        <ServiceMap />
        <Footer />
    </>
);

export default ServicePage;