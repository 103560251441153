import styled from 'styled-components';
import { Carousel, Modal, Image } from 'antd';
import { COLORS } from '../../styles/colors';

export const Container = styled(Modal)`
    top: 24px;

    .ant-modal-body {
        display: flex;
        gap: 40px;
        padding-top: 10px;
    }

    .ant-modal-close {
        height: auto;
        width: auto;
    }

    @media (orientation: portrait) {
        .ant-modal-body {
            flex-direction: column;
            padding-top: 30px;
        }
    }
`;

export const LeftContentContainer = styled.div`
    max-width: 452px;
    max-height: 452px;
    border: 2px solid ${ COLORS.black_25 };

    @media (orientation: portrait) {
        width: auto;
        height: auto;
        max-height: none;
        max-width: none;
    }
`;

export const RightContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (orientation: portrait) {
        button {
            height: 80px;
        }

        pre {
            font-size: 25px;
        }
    }
`;

export const CustomCarousel = styled(Carousel)`
    min-width: 448px;
    min-height: 448px;

    .slick-dots li button {
        background: ${ COLORS.primary };
        opacity: 0.4;
    }

    .slick-dots li.slick-active button {
        opacity: 1;
        background: ${ COLORS.primary };
    }

    span {
        svg {
            color: ${ COLORS.primary } !important;
            height: 20px;
            width: 20px;
        }
    }
`;

export const CustomImage = styled(Image)`
    aspect-ratio: 1/1;
    min-width: 448px;
    object-fit: cover;
`;