import { Container, CustomDrawer, LeftMenuContainer, MenuIcon, RightMenuContainer } from './style';
import { CustomIcon } from '../../components/CustomIcon';
import { CircledButton } from '../../components/CircledButton';
import { Contacts } from '../../components/Contacts';

import Logo from '../../resources/images/logo.svg';
import WhatsApp from '../../resources/images/whatsapp.svg';
import { Menu } from '../../components/Menu';
import React, { useState } from 'react';
import { ModalOrder } from '../../components/ModalOrder';
import { SOCIAL_MEDIA_INFORMATION } from '../../resources/info/social_media_information';

export const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { showModalOrder, renderModal } = ModalOrder({ isOrder: true });

    const onOpenHandler = () => {
        if (window.innerWidth < window.innerHeight) {
            setIsOpen(true)
        }
    }

    return (
        <Container>
            <LeftMenuContainer>
                { /* @ts-ignore */ }
                <MenuIcon onClick={ onOpenHandler } />
                <CustomIcon src={ Logo } width={ 100 } height={ 50 } />
                <CustomDrawer size="large" open={ isOpen } onClose={ () => setIsOpen(false) }>
                    <Menu isHorizontal={ false } noNeedToHide={ true } />
                </CustomDrawer>
                <Menu isHorizontal={ true } />
            </LeftMenuContainer>
            <RightMenuContainer>
                <a href={ SOCIAL_MEDIA_INFORMATION.whatsApp }>
                    <CustomIcon src={ WhatsApp } width={ 40 } height={ 40 } isHover={ true } />
                </a>
                <Contacts />
                <CircledButton color="primary" onClick={ showModalOrder }>Заказать</CircledButton>
            </RightMenuContainer>
            { renderModal() }
        </Container>
    );
};