import { Container, Li, Ul } from './style';
import React from 'react';

type TPreviewDescription = {
    isForShop?: boolean;
}

export const PreviewDescription = ({ isForShop }: TPreviewDescription) => (
    <Container>
        { isForShop
            ? <span>
                Наша компания занимается розничной продажей топливной аппаратуры для дизельных двигателей.
                Низкие цены - главное преимущество сотрудничества с нами.
                Мы осуществляем поставки напрямую с заводов-изготовителей в Китае.
                Вся наша продукция имеет гарантию от 1 года.
            </span>
            : <>
                <span>
                    Ремонт форсунок Common Rail и ТНВД в Челябинске
                </span>
                <Ul>
                    <Li>Бесплатная компьютерная диагностика в случае ремонта</Li>
                    <Li>Эвакуатор за наш счет в случаях капитального ремонта ДВС</Li>
                    <Li>Гарантия на все работы</Li>
                    <Li>Срок ремонта топливной системы 24 часа</Li>
                </Ul>
            </>
        }
    </Container>
);