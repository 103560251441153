import styled from 'styled-components';
import { CustomIconProps } from './index';
import { COLORS } from '../../styles/colors';

export const Container = styled.img<CustomIconProps>`
    transition: 0.3s ease-in-out;

    ${ iconProps => iconProps.isHover
            ? `
                @media (orientation: portrait) {
                    height: 60px;
                    width: 60px;
                }
            `
            : null
    };

    ${ iconProps => iconProps.isForComment 
        ? `
            border-radius: ${ iconProps.width/2 }px;
            border: 2px ${ COLORS.primary } solid;
            @media (orientation: portrait) {
                height: ${ iconProps.height * 1.5 }px;
                width: ${ iconProps.width * 1.5 }px;
                border-radius: ${ iconProps.width * 1.5 / 2 }px;

            }
        `
        : null
    };

    &:hover {
        ${ iconProps => iconProps.isHover
            ? 'transform: scale(1.2)'
            : null
        };

        @media (orientation: portrait) {
            ${ iconProps => iconProps.onClick
                    ? 'cursor: pointer'
                    : null
            };
        }
    }
`;