import React from 'react';
import { Container } from './style';

type DefaultButtonProps = {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children?: React.ReactNode;
    type?: 'button' | 'submit' | 'reset';
};

export const DefaultButton = ({ onClick, children, type = 'button' }: DefaultButtonProps) => (
    <Container onClick={ onClick } type={ type }>
        { children }
    </Container>
);
