export const COLORS = {
    primary: 'rgba(237, 32, 36, 1)',
    secondary: 'rgba(247, 87, 22, 1)',
    gradient: 'linear-gradient(90deg, rgba(237, 32, 36, 1), rgba(247, 87, 22, 1))',
    dark_gradient: 'linear-gradient(120.4deg, rgba(37, 37, 37, 0.85) 0%, #212935 68.49%)',
    black_100: 'rgba(37, 37, 37, 1)',
    black_90: 'rgba(37, 37, 37, 0.9)',
    black_80: 'rgba(37, 37, 37, 0.85)',
    black_75: 'rgba(37, 37, 37, 0.75)',
    black_50: 'rgba(37, 37, 37, 0.5)',
    black_25: 'rgba(37, 37, 37, 0.25)',
    black_10: 'rgba(37, 37, 37, 0.1)',
    black_5: 'rgba(37, 37, 37, 0.05)',
    white: 'rgba(255, 255, 255, 1)',
    white_90: 'rgba(255, 255, 255, 0.9)',
} as const;