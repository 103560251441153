import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const Container = styled.div`
    margin-top: 10px;

    span {
        color: ${ COLORS.white_90 };
        font-size: 30px;
    }
`;

export const Li = styled.li`
    color: ${ COLORS.white_90 };
    font-size: 25px;
    line-height: 50px;
`;

export const Ul = styled.ul`
    padding-top: 20px;
    padding-left: 25px;
`;