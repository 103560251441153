import React from 'react';
import { Container } from './style';

export type CircledButtonProps = {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children?: React.ReactNode;
    color?: 'primary' | 'secondary';
};

export const CircledButton = ({ onClick, children, color = 'primary' }: CircledButtonProps) => (
    <Container onClick={ onClick } color={ color }>
        { children }
    </Container>
);
