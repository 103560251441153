import { Item, ItemsList } from './style';

export type ItemInfo = {
    name?: string;
    isColored?: boolean;
    attribute: 'tel' | 'mailto';
}

const ITEM_DATA: Array<ItemInfo> = [
    { name: 'mestodizelya@inbox.ru', isColored: true, attribute: 'mailto' },
    { name: '+7 (993) 928-25-64', attribute: 'tel' },
];

const renderItems = ITEM_DATA.map((item, id) => (
    <Item isColored={ item.isColored } key={ id } attribute={ item.attribute }>
        <a href={ `${ item.attribute }:${ item.name }` }>{ item.name }</a>
    </Item>
));


export const Contacts = () => (
    <ItemsList>
        { renderItems }
    </ItemsList>
);