import styled from 'styled-components';
import { COLORS } from '../../styles/colors';
import { Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

export const Container = styled.header`
    background: ${ COLORS.white };
    height: 80px;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: clamp(10px, 1vw, 150px);

    @media (orientation: portrait) {
        height: 120px
    }
`;

export const LeftMenuContainer = styled.div`
    gap: 2vw;
    display: flex;
    align-items: center;
    padding: 0;
`;

export const RightMenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2vw;
`;

export const CustomDrawer = styled(Drawer)`
    width: auto;

    & .ant-drawer-header {
        display: none;
    }

    & .ant-drawer-body {
        background: ${ COLORS.dark_gradient};
    }
`;

export const MenuIcon = styled(MenuOutlined)`
    display: none;

    @media (orientation: portrait) {
        display: initial;
        transition-duration: 0.2s;

        svg:hover {
            color: ${ COLORS.primary };
        }

        svg {
            width: 60px;
            height: 60px;
        }
    }
`;