import styled from 'styled-components';
import { ItemInfo } from './index';
import { COLORS } from '../../styles/colors';

export const ItemsList = styled.ul`
    padding-bottom: 5px;
    width: min-content;
`;

export const Item = styled.li<ItemInfo>`
    display: inline-block;
    padding-bottom: 5px;

    a {
        color: ${ ({ isColored }: ItemInfo) => isColored ? COLORS.primary : COLORS.black_90 };
    }

    a:hover {
        color: ${ ({ isColored }: ItemInfo) => isColored ? COLORS.secondary : COLORS.black_75 };
    }
`;