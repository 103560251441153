const spaceBetween = 30;

export const BREAKPOINT_SWIPER = {
    0: {
        slidesPerView: 1,
        spaceBetween
    },
    480: {
        slidesPerView: 1,
        spaceBetween
    },
    768: {
        slidesPerView: 2,
        spaceBetween
    },
    1024: {
        slidesPerView: 3,
        spaceBetween
    },
    1280: {
        slidesPerView: 3,
        spaceBetween
    }
} as const;