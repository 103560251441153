import styled from 'styled-components';
import { Card, Image } from 'antd';
import { COLORS } from '../../styles/colors';

export const CustomCard = styled(Card)`
    background: ${ COLORS.black_5 };
    border-radius: 5px;
    min-width: 33%;
    box-shadow: 1px 1px 17px 0 rgba(0,0,0,0.2);
    -webkit-box-shadow: 1px 1px 17px 0 rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 1px 17px 0 rgba(0,0,0,0.2);

    .ant-card-meta {
        padding: 0 0 20px 0;
    }

    .ant-card-body {
        padding: 24px 38px 24px 38px;
    }

    .ant-card-meta-title, .ant-card-meta-description {
        font-size: 20px;
        font-weight: 500 !important;
    }

    @media (orientation: portrait) {
        .ant-card-meta-title, .ant-card-meta-description {
            font-size: 25px;
        }

        .ant-card-meta-description {
            color: ${ COLORS.black_90 }
        }
    }
`;

export const CardImage = styled(Image)`
    object-fit: cover;
    aspect-ratio: 1/1;
`;