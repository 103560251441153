import { CustomCard, CardImage } from './style';
import { DefaultButton } from '../DefaultButton';
import { Product } from '../../layouts/Catalog';
import { FALLBACK_IMAGE } from '../../resources/info/util_constants';
import React, { useState } from 'react';
import { ModalProduct } from '../ModalProduct';

const { Meta } = CustomCard;

export const Card = (product: Product) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
            <CustomCard cover={
                <CardImage
                    alt={ product.name }
                    src={ product.images[0] }
                    preview={ false }
                    fallback={ FALLBACK_IMAGE }
                />
            }>
                <Meta title={ `${ product.company } ${ product.name }` } description={ `${ product.price } р.` } />
                <DefaultButton onClick={ showModal }>Подробнее</DefaultButton>
                {
                    isModalOpen
                        ? <ModalProduct isModalOpen={ isModalOpen } onCancel={ handleCancel } productInfo={ product } />
                        : null
                }
            </CustomCard>
    );
};