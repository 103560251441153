import { SwiperSlide } from 'swiper/react';
import { SwiperContainer, Container } from './style';
import { FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import { Product } from '../../layouts/Catalog';
import { Card } from '../Card';
import React from 'react';
import { BREAKPOINT_SWIPER } from '../../resources/info/breakpoint_constants';
import 'swiper/css/navigation';

import './customSwiperNavigation.css';

export const ProductCardSwiper = (products: Product[]) => {
    const allSortedProducts = new Map<string, Product[]>();
    const renderedSwipers = new Array<JSX.Element>();

    Object.values(products).forEach((product) => {
        const category = product.category;
        if (allSortedProducts.has(category)) {
            allSortedProducts.get(category).push(product);
        } else {
            allSortedProducts.set(category, [product]);
        }
    });

    allSortedProducts.forEach((companySortedProducts, title) => {
        renderedSwipers.push(
            <Container key={ title }>
                <h2>{ title }</h2>
                <SwiperContainer
                    freeMode={ true }
                    grabCursor={ true }
                    modules={ [FreeMode, Navigation] }
                    breakpoints={ BREAKPOINT_SWIPER }
                    navigation
                >
                    {
                        Object.values(companySortedProducts).map((companyProduct, index) => {
                            return <SwiperSlide key={ index }>
                                <Card { ...companyProduct } />
                            </SwiperSlide>;
                        })
                    }
                </SwiperContainer>
            </Container>
        );
    });

    return <div> { renderedSwipers } </div>;
};