import { createGlobalStyle } from 'styled-components';
import { COLORS } from './colors';

const GlobalStyle = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: "Segoe UI", Arial, sans-serif;
        font-style: normal;
        font-weight: 500 !important;
    }

    html, body, #root {
        height: 100%;
        overflow-x: hidden;
        background: #ffffff;
    }

    button {
        border: none;
        outline: none;
        cursor: pointer;
        height: 45px;
        font-weight: 500 !important;
    }

    a, p, span, pre, button, .ant-input, h1 {
        text-decoration: none;
        outline: none;
        font-size: 18px;
        color: ${ COLORS.black_80 };
    }

    span[role=img] {
        font-size: initial;
        outline: initial;
        text-decoration: initial;
    }

    h2 {
        font-size: 30px;
        color: ${ COLORS.black_80 };
    }

    .ant-input::placeholder {
        font-size: 16px;
        color: ${ COLORS.black_25 };
    }

    pre {
        white-space: pre-wrap;
        text-align: left;
    }

    h1 {
        font-size: 70px;
        color: ${ COLORS.black_80 };
    }

    h3 {
        font-size: 24px;
        font-weight: 500;
        color: ${ COLORS.black_80 };
    }

    .ant-modal-content {
        border: 2px solid ${ COLORS.primary } !important;
    }

    @media (orientation: portrait) {
        a, p, span, pre, button, .ant-input, .ant-input::placeholder {
            font-size: 26px;
        }

        h3 {
            font-size: 30px;
        }

        h2 {
            font-size: 50px;
        }

        h1, h2, h3 {
            font-weight: 600 !important;
        }


        .ant-card button {
            font-size: 25px;
            color: ${ COLORS.black_90 }
        }
    }
`;

export default GlobalStyle;