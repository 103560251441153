import styled from 'styled-components';
import { Row } from 'antd';
import { CommonContainer, CommonSection } from '../../styles/common.style';
import { COLORS } from '../../styles/colors';
import { Input } from 'antd';

export const Section = styled(CommonSection)`
    background: ${ COLORS.white };
`;

export const SearchContainer = styled(Input)`
    border: 2px ${ COLORS.primary } solid;
    border-radius: 10px;
    max-width: 500px;
    height: 50px;
    margin: 10px;
    padding-bottom: 8px;

    &:hover, &:focus {
        border-color: ${ COLORS.primary };
        border-radius: 5px;
        border: 2px ${ COLORS.primary } solid;
    }
`;

export const Container = styled(CommonContainer)``;

export const ContentContainer = styled(Row)`
    padding-top: 50px;
    flex-wrap: wrap;
`;

export const TextContainer = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    span {
        font-size: 30px;
    }
`;
