import { Preview } from '../layouts/Preview';
import { Catalog } from '../layouts/Catalog';
import { FAQ } from '../layouts/FAQ';
import { Comments } from '../layouts/Comments';
import React from 'react';
import { Header } from '../layouts/Header';
import { Footer } from '../layouts/Footer';

const ShopPage = () => (
    <>
        <Header />
        <Preview isForShop={ true } />
        <Catalog />
        <FAQ />
        <Comments />
        <Footer />
    </>
);

export default ShopPage;