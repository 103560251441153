import { Container, LeftFooterContainer, RightFooterContainer, SocialMedia } from './style';
import { CustomIcon } from '../../components/CustomIcon';
import FullLogo from '../../resources/images/full_logo.svg';
import { Menu } from '../../components/Menu';
import { COMPANY_INFORMATION } from '../../resources/info/company_information';
import { SOCIAL_MEDIA_INFORMATION } from '../../resources/info/social_media_information';
import WhatsApp from '../../resources/images/whatsapp_white.svg';
import React from 'react';

const getCompanyInfo = COMPANY_INFORMATION.map(({ title, description, attribute }, id) => {
    if (attribute) {
        return <a key={ id } href={ `${ attribute }:${ description }` } style={{ whiteSpace: 'nowrap' }}>{ description }</a>
    }

    return <span key={ id }>{ title ? `${ title }: ` : '' }{ description }</span>;
});

export const Footer = () => (
    <Container>
        <LeftFooterContainer>
            <CustomIcon src={ FullLogo } width={ 300 } height={ 75 } />
            { getCompanyInfo }
            <SocialMedia>
                <a href={ SOCIAL_MEDIA_INFORMATION.whatsApp }>
                    <CustomIcon src={ WhatsApp } width={ 40 } height={ 40 } isHover={ true } />
                </a>
            </SocialMedia>
        </LeftFooterContainer>
        <RightFooterContainer>
            <h3>Меню:</h3>
            <Menu isHorizontal={ false } />
        </RightFooterContainer>
    </Container>
);