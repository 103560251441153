import image_1 from '../images/comment_1.jpg';
import image_2 from '../images/comment_2.jpg';
import image_3 from '../images/comment_3.jpg';


type PreviewInfo = {
    readonly description: string;
    readonly image: string;
};

export const PREVIEW_INFORMATION: ReadonlyArray<PreviewInfo> = [
    { description: 'Долго искал хороший магазин, где и цены приятные и люди отзывчивые. Проконсультировали по всем интересующим вопросам.', image: image_1 },
    { description: 'Заказывал ТНВД для Хендай 78. Доставили быстро. Пришёл целый, на машине работает идеально.', image: image_2 },
    { description: 'Покупал форсунки в данном магазине на Фиат дукато. Всё работает отлично, спасибо ребятам!', image: image_3 }
];