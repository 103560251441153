import { Container } from './style';
import React from 'react';

export type CustomIconProps = {
    src: string;
    alt?: string;
    width?: number;
    height?: number;
    isHover?: boolean;
    isForComment?: boolean;
    onClick?: React.MouseEventHandler<HTMLImageElement>;
}

export const CustomIcon = ({ src, alt, width = 100, height = 100, isHover = false, isForComment = false, onClick }: CustomIconProps) => (
    <Container src={ src } alt={ alt } width={ width } height={ height } isHover={ isHover } isForComment={ isForComment } onClick={ onClick } />
);