import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const Container = styled.button`
    padding-inline: 30px;
    text-align: center;
    border: 1px solid ${ COLORS.primary };
    border-radius: 10px;
    background: transparent;
    line-height: 130%;
    width: fit-content;
    transition-duration: 0.3s;

    ::after {
        border-radius: 10px;
    }

    &:hover {
        color: ${ COLORS.white };
        background: ${ COLORS.primary };
    }

    @media (orientation: portrait) {
        height: 60px;
    }
`;