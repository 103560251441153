import React from 'react';
import GlobalStyle from '../styles/global.style';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ShopPage from './Shop';
import ServicePage from './Service';

const router = createBrowserRouter([
    {
        path: '/shop',
        element: <ShopPage />
    },
    {
        path: '/service',
        element: <ServicePage />
    },
    {
        path: '*',
        element: <ShopPage />
    }
]);

const MainPage = () => (
    <>
        <GlobalStyle />
        <RouterProvider router={ router } />
    </>
);

export default MainPage;
