import { Container, TextContainer, Section, SearchContainer } from './style';
import React, { useEffect, useState } from 'react';
import { LayoutName } from '../../components/LayoutName';
import { ProductCardSwiper } from '../../components/Swiper';

export type Product = {
    name: string;
    company: string;
    images: string[];
    description: string;
    price: number;
    category: string;
};

type ProductResponse = {
    products: Product[];
};

export const Catalog = () => {
    const [filteredProducts, setFilteredProduct] = useState<Product[]>([]);
    const [products, setProduct] = useState<Product[]>([]);

    useEffect(() => {
        (async () => {
            const response: Response = await fetch('api/product/get', {
                method: 'GET'
            });
            const responseBody: ProductResponse = await response.json();
            setFilteredProduct(responseBody.products);
            setProduct(responseBody.products);
        })();
    }, []);

    const filterProducts = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value === '') {
            setFilteredProduct(products);
        } else {
            setFilteredProduct(products.filter(product =>
                `${ product.name }${ product.company }${ product.category }${ product.description }`
                    .toLowerCase()
                    .includes(event.currentTarget.value.toLowerCase())
            ));
        }
    };

    return (
        <Section>
            <Container id="catalog">
                <TextContainer>
                    <LayoutName color="black">Каталог</LayoutName>
                    <span>Всегда в наличии самые популярный модели дизельных форсунок, топливных насосов высокого и низкого давления.</span>
                    <SearchContainer onChange={ filterProducts } placeholder="Поиск по каталогу" />
                </TextContainer>
                <ProductCardSwiper { ...filteredProducts } />
            </Container>
        </Section>
    );
};