import { Container } from './style';
import { PREVIEW_INFORMATION } from '../../resources/info/preview_information';
import { CustomIcon } from '../CustomIcon';

const renderComment = PREVIEW_INFORMATION.map((preview) => (
    <div key={ preview.description }><CustomIcon src={ preview.image } isForComment={ true } /><p>{ preview.description }</p></div>
));

export const Comment = () => (
    <Container>
        { renderComment }
    </Container>
);