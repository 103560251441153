import styled from 'styled-components';
import background from '../../resources/images/preview.png';
import { CommonContainer, CommonSection } from '../../styles/common.style';
import { COLORS } from '../../styles/colors';

export const CustomSection = styled(CommonSection)`
    background: url(${ background }) no-repeat;
    background-size: cover;
    min-height: 850px;
    position: relative;
`;

export const TextContainer = styled.div`
    h1 {
        color: ${ COLORS.white_90 };
        font-weight: 700;
        line-height: 130%;
    }
`;

export const ContentContainer = styled(CommonContainer)`
    margin: unset;
`;