import styled from 'styled-components';
import { CommonContainer, CommonSection } from '../../styles/common.style';

export const Section = styled(CommonSection)`

`;

export const Container = styled(CommonContainer)`
    display: flex;
    flex-direction: column;
    gap: 90px;
`;

export const TextContainer = styled.div`
    text-align: center;
`;

export const ContentContainer = styled.div``;