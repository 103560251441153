import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const Container = styled.div`
    background: ${ COLORS.gradient };
    border-radius: 20px;
    padding-inline: 3%;
    padding-block: 3%;
    display: flex;
`;

export const ContentContainer = styled.div`
    gap: 2vw;
    display: flex;
    align-items: center;
    margin: auto;

    span {
        color: ${ COLORS.white };
    }

    @media (orientation: portrait) {
        flex-direction: column;
        text-align: center;
    }
`;