import { ContentContainer, TextContainer, Section, Container } from './style';
import { CustomCollapse } from '../../components/Collapse';
import { LayoutName } from '../../components/LayoutName';
import { FAQ_INFORMATION } from '../../resources/info/faq_information';

export const FAQ = () => (
    <Section>
        <Container id="faq">
            <TextContainer>
                <LayoutName color="white">Вопросы - ответы</LayoutName>
            </TextContainer>
            <ContentContainer>
                {
                    FAQ_INFORMATION.map((faq, index) => (
                        <CustomCollapse header={ faq.header } text={ faq.text } key={ index } />
                    ))
                }
            </ContentContainer>
        </Container>
    </Section>
);