import { Container } from './style';

const { Panel } = Container;

type CustomCollapseProps = {
    header: string;
    text: string;
}

export const CustomCollapse = ({ header, text }: CustomCollapseProps) => (
    <Container collapsible="header" expandIcon={ () => null }>
        <Panel header={ header } key={ header }>
            <pre>{ text }</pre>
        </Panel>
    </Container>
);