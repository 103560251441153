type faqInfo = {
    readonly header: string;
    readonly text: string;
}

export const FAQ_INFORMATION: readonly faqInfo[] = [
    {
        header: 'Как сделать заказ?',
        text:
`
1. Выберите товары и добавьте их в корзину;
2. Перейдите в раздел «Корзина» и нажмите «Перейти к оформлению»;
3. Укажите ваши данные;
4. Выберите способ доставки и укажите адрес;
5. Выберите способ оплаты и нажмите «Оплатить онлайн»;
6. Оплатите заказ, и он поступит в обработку; ( подставить наши данные которые будут на сайте).
`
    },
    {
        header: 'При каких условиях возможен возврат товара?',
        text:
`
Вы можете вернуть товар, если:

· товар не подошел или не понравился;
· товар пришел с браком, повреждениями или не в полной комплектации;
· товар сломан и не функционирует;
· доставили не тот товар;
· курьер не приехал за возвратом.

Вы можете вернуть товар: в пункт выдачи, постамат, курьером или почтой. Если возникли вопросы, можете обратиться к менеджерам по телефонам, указанным на сайте.
`
    },
    {
        header: 'Возможен ли самовывоз заказанного товара?',
        text:
`
Да, возможен. Вы можете самостоятельно забрать заказ в пунктах выдачи товаров в городе Челябинск.
`
    },
    {
        header: 'Как осуществляется доставка в регионы?',
        text:
`
Предусмотрено несколько вариантов доставки: транспортные компании, курьерская доставка и почта России.
Вы можете выбрать любой удобный для вас способ и указать желаемую дату доставки. Оплата рассчитывается автоматически при указании региона и даты.
Для более точного расчета стоимости доставки обращайтесь к менеджерам по телефонам, указанным на сайте.

Для Физических лиц: 
1. При оформлении заказа необходимо указать реальные данные (ФИО, контактный телефон, адрес электронной почты).
2. После проверки наличия и резервирования товара, работники нашей компании свяжутся с заказчиком для подтверждения наличия и уточнения условий покупки и оплаты. 
Для отправки заказанного товара транспортными компаниями, оплату возможно произвести переводом на карту Сбербанка после согласования с сотрудником нашей компании. 
Наша компания БЕСПЛАТНО доставит заказ до любой транспортной компании в городе Челябинск в день заказа, если он оформлен, подтвержден и оплачен до 18:00. 
Доставка товара по Челябинску и Челябинской области обсуждается и рассчитывается в индивидуальном порядке, исходя из стоимости товара, удаленности места доставки, а также дорожной обстановки.
 
Для Юридических лиц: 
1. При оформлении заказа необходимо указать реальные данные (Название организации, контактный телефон, адрес электронной почты).
2. После проверки наличия и резервирования товара, работники нашей компании свяжутся по контактному телефону для подтверждения наличия товара на складе и цены.
3. На почту (почта сайта) необходимо прислать карточку предприятия с указанием наименования товара или номера заказа.
4. После выставления счета на оплату, в 3-дневный срок необходимо произвести оплату по счету и отправить платежное поручение на почтовый ящик (Почта сайта)
5. После поступления денежных средств на расчетный счет нашей компании, заказанный товар можно получить в офисе г. Челябинск 
6. Для получения товара необходимо иметь оригинал доверенности, либо печать организации.

Так же мы БЕСПЛАТНО доставим заказ до любой транспортной компании в г. Челябинск,если Вы находитесь в другом регионе. Вместе с заказанным товаром Вы получите все необходимые документы для проведения покупки по бухгалтерии (Счет, УПД).
Доставка в страны Таможенного союза (Казахстан, Киргизия, Беларусь, Армения)
Доставка в указанные страны осуществляется курьерскими компаниями «СДЭК» и «Boxberry». Стоимость доставки в Казахстан рассчитывается автоматически при оформлении заказа; в Беларусь, Киргизию и Армению по запросу через электронную почту (укажите город, позицию, количество) или WhatsApp. Способ оплаты по договоренности с клиентом.
`
    },
    {
        header: 'Какие возможны способы оплаты?',
        text:
`
Оплатить заказ можно любым удобным для вас способом: банковская карта или перевод, оплата через расчётный счёт,оплата на электронный кошелек. Также возможна оплата наличными при получении заказа.
`
    }
];