import { Container, ContentContainer } from './style';
import { CircledButton } from '../CircledButton';
import React from 'react';
import { ModalOrder } from '../ModalOrder';

type TCommentFeedback = {
    isForService?: boolean
}

export const CommentFeedback = ({ isForService }: TCommentFeedback) => {
    const { showModalOrder, renderModal } = ModalOrder({ isOrder: isForService ?? false });

    return <Container>
        <ContentContainer>
            { isForService
                ? <span>
                    Оставьте заявку на диагностику вашего автомобиля
                </span>
                : <span>
                    У вас есть что сказать? Мы были бы счастливы это услышать! Вы можете оставить отзыв
                </span>
            }
            <CircledButton color="secondary" onClick={ showModalOrder }>
                { isForService ? 'Записаться' : 'Оставить отзыв' }
            </CircledButton>
        </ContentContainer>
        { renderModal() }
    </Container>;
};