import { CustomSection, ContentContainer, TextContainer } from './style';
import { PreviewDescription } from '../../components/PreviewDescription';
import React from 'react';

type TPreview = {
    isForShop?: boolean;
};

export const Preview = ({ isForShop }: TPreview) => (
    <CustomSection>
        <ContentContainer>
            <TextContainer>
                <h1>Место Дизеля</h1>
                <PreviewDescription isForShop={ isForShop } />
            </TextContainer>
        </ContentContainer>
    </CustomSection>
);