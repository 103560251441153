import styled, { css } from 'styled-components';
import { CircledButtonProps } from './index';
import { COLORS } from '../../styles/colors';

const COLOR = {
    primary: css`
        border: ${ COLORS.primary } 2px solid;
        transition-duration: 0.3s;

        &:hover {
            background: ${ COLORS.primary };
        }
    `,
    secondary: css`
        border: ${ COLORS.black_100 } 2px solid;
        transition-duration: 0.3s;

        &:hover {
            background: ${ COLORS.black_90 };
        }
    `,
};

export const Container = styled.button<CircledButtonProps>`
    ${ (props) => props.color && COLOR[props.color] }
    color: ${ COLORS.black_90 };
    width: auto;
    text-align: center;
    background: ${ COLORS.white };
    border-radius: 100px;
    white-space: nowrap;
    height: 44px;
    padding-inline: 40px;
    display: flex;
    align-items: center;
    padding-bottom: 4px;

    &:hover {
        color: ${ COLORS.white };
    }

    @media (orientation: portrait) {
        padding-block: 10px;
        height: auto;
    }
`;