import styled, { css } from 'styled-components';
import { MenuProps } from './index';
import { COLORS } from '../../styles/colors';

const MENU_TYPE = {
    horizontal: css`
        grid-auto-flow: column;
        padding-bottom: 5px;
        gap: 2vw;
    `,
    vertical: css`
        gap: 15px;
        list-style: none;

        a {
            color: ${ COLORS.white };
        }
    `,
};

export const ItemsList = styled.ul<MenuProps>`
    display: grid;
    ${ ({ isHorizontal }: MenuProps) => isHorizontal ? MENU_TYPE.horizontal : MENU_TYPE.vertical };

    @media (max-width: 900px) {
        width: min-content;
        white-space: break-spaces;
        gap: 1vw;
    }

    @media (orientation: portrait) {
        ${ ({ noNeedToHide }: MenuProps) => noNeedToHide ? '' : 'display: none;' };
        ${ ({ noNeedToHide }: MenuProps) => noNeedToHide ? 'width: auto;' : '' };
        ${ ({ noNeedToHide }: MenuProps) => noNeedToHide ? `
            li a {
                font-size: 40px;
            }
        ` : '' };
    }
`;

export const Item = styled.li`
    display: inline-block;
    transition: color 0.25s ease-in-out;

    &:hover {
        a {
            color: ${ COLORS.primary };
        }
    }
`;