import { Input, message } from 'antd';
import React, { useState } from 'react';

import { DefaultButton } from '../DefaultButton';
import { Container, ContentContainer } from './style';

type ModalOrderType = {
    productName?: string;
    productPrice?: number;
    isOrder?: boolean;
};

export const ModalOrder = ({ productName, isOrder }: ModalOrderType) => {
    const [isModalOrderOpen, setIsModalOrderOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const showModalOrder = () => {
        setIsModalOrderOpen(true);
    };

    const handleCancel = () => {
        setIsModalOrderOpen(false);
    };

    const handleSubmitForm = async () => {
        messageApi.open({
            type: 'success',
            content: 'Спасибо, мы свяжемся с вами в ближайшее время для уточнения деталей заказа. Ожидайте!',
            duration: 4
        });
    };

    const renderModal = () => (
        <Container
            open={ isModalOrderOpen }
            onCancel={ handleCancel }
            footer={ null }
            width="fit-content"
        >
            <ContentContainer action="https://formsubmit.co/mestodizelya@inbox.ru" method="POST">
                <span>{ isOrder ? 'Сделать заказ' : 'Ваш отзыв' }</span>
                <Input type="hidden" name="_subject" value={ isOrder ? 'ЗАКАЗ' : 'ОТЗЫВ' } />
                <Input type="hidden" name="_next" value="https://mestodizelya.ru" />
                <Input type="hidden" name="_captcha" value="false" />
                <Input
                    type="tel"
                    name="Контактный телефон"
                    placeholder="Номер телефона"
                    pattern="[0-9]{11,12}"
                    required
                />
                <Input type="text" name="Имя клиента" placeholder="Ваше имя" required />
                { isOrder &&
                    <Input type="text" name="Марка и модель авто" placeholder="Марка и модель авто" />
                }
                { !isOrder &&
                    <>
                        <Input type="text" name="Город" placeholder="Город" />
                        <Input.TextArea
                            name="Дополнительная информация"
                            placeholder={ isOrder ? 'Дополнительная информация' : 'Текст отзыва' }
                        />
                    </>
                }
                { isOrder &&
                    <Input type="hidden" name="Название товара" value={ productName ?? 'Не выбран' } />
                }
                <DefaultButton type="submit">Отправить</DefaultButton>
            </ContentContainer>
        </Container>
    );

    return { isModalOrderOpen, showModalOrder, renderModal };
};