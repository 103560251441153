import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    gap: 100px;

    div {
        width: auto;
    }

    @media (orientation: portrait) {
        flex-direction: column;
    }
`;