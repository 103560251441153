import React from 'react';
import { Product } from '../../layouts/Catalog';
import { Container, LeftContentContainer, RightContentContainer, CustomCarousel, CustomImage } from './style';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { FALLBACK_IMAGE } from '../../resources/info/util_constants';
import { DefaultButton } from '../DefaultButton';
import { ModalOrder } from '../ModalOrder';

type ModalProductType = {
    isModalOpen: boolean;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    productInfo: Product;
};

export const ModalProduct = ({ isModalOpen, onCancel, productInfo }: ModalProductType) => {
    const { showModalOrder, renderModal } = ModalOrder({ isOrder: true, productName: `${ productInfo.company } ${ productInfo.name }`, productPrice: productInfo.price });

    return <Container
        open={ isModalOpen }
        onCancel={ onCancel }
        footer={ null }
        width={ 950 }
    >
        <LeftContentContainer>
{ /* @ts-ignore */ }
            <CustomCarousel arrows nextArrow={ <RightOutlined height={ 20 } width={ 20 } /> } prevArrow={ <LeftOutlined height={ 20 } width={ 20 } /> }>
                {
                    productInfo.images.map((image, index) => (
                        <CustomImage src={ image } fallback={ FALLBACK_IMAGE } preview={ false } key={ index } />
                    ))
                }
            </CustomCarousel>
        </LeftContentContainer>
        <RightContentContainer>
            <p>{ `${ productInfo.company } ${ productInfo.name }` }</p>
            <p>{ `${ productInfo.price } р.` }</p>
            <DefaultButton onClick={ showModalOrder }>Заказать</DefaultButton>
            <p>Описание:</p>
            <pre>{ productInfo.description }</pre>
        </RightContentContainer>
        { renderModal() }
    </Container>;
};