import styled from 'styled-components';
import { CommonContainer, CommonSection } from '../../styles/common.style';
import { COLORS } from '../../styles/colors';

export const Section = styled(CommonSection)`
    background: ${ COLORS.dark_gradient };
`;

export const Container = styled(CommonContainer)``;

export const ContentContainer = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    text-align: center;
    padding-top: 20px;
`;

export const TextContainer = styled.div`
    text-align: center;
`;