import { CommonTextContainer, DarkSection } from '../../styles/common.style';
import { CommentFeedback } from '../../components/CommentFeedback';
import React from 'react';
import { LayoutName } from '../../components/LayoutName';
import { Container } from './style';

export const Contact = () => {
    return (
        <DarkSection>
            <Container>
                <CommonTextContainer>
                    <LayoutName color="white">Оставьте заявку на диагностику</LayoutName>
                </CommonTextContainer>
                <CommentFeedback isForService />
            </Container>
        </DarkSection>
    );
};