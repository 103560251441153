import React from 'react';
import {
    GeolocationControl,
    GeoObject,
    Map,
    Placemark,
    TrafficControl,
    YMaps,
    ZoomControl
} from '@pbe/react-yandex-maps';

import { CommonContainer, LightSection } from '../../styles/common.style';
import { TextContainer } from '../Price/style';
import { LayoutName } from '../../components/LayoutName';
import { MapContainer } from './style';
import { COLORS } from '../../styles/colors';

export const ServiceMap = () => {
    return (
        <LightSection>
            <CommonContainer>
                <TextContainer>
                    <LayoutName color="black">Наш адрес</LayoutName>
                </TextContainer>
                <MapContainer>
                    <YMaps>
                        <Map
                            defaultState={ { center: [55.094648, 61.385153], zoom: 17 } }
                            style={ { width: '99.9%', 'height': '99.9%', zIndex: '1' } }
                            options={ {
                                mapAutoFocus: false,
                            } }
                        >
                            <GeoObject
                                geometry={ {
                                    type: 'LineString',
                                    coordinates: [
                                        [55.095186, 61.387817],
                                        [55.095035, 61.385612],
                                        [55.094898, 61.385349],
                                        [55.094829, 61.385044],
                                        [55.094648, 61.385153]
                                    ],
                                } }
                                options={ {
                                    geodesic: true,
                                    strokeWidth: 3,
                                    strokeColor: COLORS.primary,
                                } }
                            />
                            <Placemark geometry={ [55.094648, 61.385153] } />
                            <GeolocationControl options={ { float: 'left' } } />
                            <TrafficControl />
                            <ZoomControl />
                        </Map>
                    </YMaps>
                </MapContainer>
            </CommonContainer>
        </LightSection>
    );
};