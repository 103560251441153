import { ItemsList, Item } from './style';
import { Link } from 'react-router-dom';
import React from 'react';

type ItemInfo = {
    link: string;
    name: string;
};

export type MenuProps = {
    isHorizontal: boolean;
    noNeedToHide?: boolean;
};

const ITEM_DATA: Array<ItemInfo> = [
    { link: '/shop', name: 'Магазин' },
    { link: '/service', name: 'Ремонт форсунок Common Rail и ТНВД' },
];

const renderItems = ITEM_DATA.map((item) => {
    return (
        <Item key={ item.name }>
            <Link to={ item.link }>{ item.name }</Link>
        </Item>
    );
});

export const Menu = ({ isHorizontal, noNeedToHide }: MenuProps) => (
    <ItemsList isHorizontal={ isHorizontal } noNeedToHide={ noNeedToHide }>
        { renderItems }
    </ItemsList>
);