import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const MapContainer = styled.div`
    margin-top: 20px;
    border: 1px solid ${ COLORS.primary };
    height: 500px;
    box-shadow: 1px 1px 17px 0 rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px 1px 17px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 17px 0 rgba(0,0,0,0.75);
`;