import { Container } from './style';
import React from 'react';

export type LayoutNameProps = {
    children?: React.ReactNode;
    color: 'black' | 'white';
};

export const LayoutName = ({ children, color }: LayoutNameProps) => (
    <Container color={ color }>{ children }</Container>
);