import styled from 'styled-components';
import { List } from 'antd';
import { COLORS } from '../../styles/colors';

export const TextContainer = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    span {
        font-size: 30px;
    }
`;

export const DataContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    padding-top: 20px;

    @media (orientation: portrait) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`;

export const ItemContainer = styled(List.Item)`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 18px;

    @media (orientation: portrait) {
        span {
            font-size: 30px;
        }
    }
`;

export const CustomList = styled(List)`
    border-color: ${ COLORS.primary };
    box-shadow: 1px 1px 17px 0 rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px 1px 17px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 17px 0 rgba(0,0,0,0.75);

    li:not(:last-child) {
        border-block-end: 1px solid ${ COLORS.primary } !important;
    }
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (orientation: portrait) {
        span {
            font-size: 15px;
        }

        padding-bottom: 20px;
    }
`;