import { Container, Section, TextContainer, ContentContainer } from './style';
import { CommentFeedback } from '../../components/CommentFeedback';
import { Comment } from '../../components/Comment';
import { LayoutName } from '../../components/LayoutName';

export const Comments = () => (
    <Section>
        <Container id="reviews">
            <TextContainer>
                <LayoutName color="black">Отзывы наших клиентов</LayoutName>
            </TextContainer>
            <ContentContainer>
                <Comment />
            </ContentContainer>
            <CommentFeedback />
        </Container>
    </Section>
);