import styled from 'styled-components';
import { Collapse } from 'antd';
import { COLORS } from '../../styles/colors';

export const Container = styled(Collapse)`
    background: ${ COLORS.white };
    border-color: ${ COLORS.primary };
    transition-duration: 1s;

    div {
        border: none;
    }

    .ant-collapse-content {
        background: ${ COLORS.white };
        border: none;
        padding-bottom: 20px;
    }

    .ant-collapse-content-box {
        padding-inline: 20px !important;
        padding-block: 0 !important;
    }

    .ant-collapse-item {
        border: 1px solid ${ COLORS.primary };
        border-radius: 8px !important;
    }
    
    .ant-collapse-header-text {
        width: fit-content;
        text-align: left;
    }
`;