type CompanyInfo = {
    title?: string;
    description: string;
    attribute?: 'tel' | 'mailto';
};

export const COMPANY_INFORMATION: readonly CompanyInfo[] = [
    { title: 'Адрес', description: 'г. Челябинск, Троицкий тракт 19В, офис 203 и 214' },
    { title: 'Время работы', description: '9:00-18:00' },
    { description: '+7 (993) 928-25-64', attribute: 'tel' },
    { description: '+7 (982) 328-25-64', attribute: 'tel' },
    { description: ' mestodizelya@inbox.ru', attribute: 'mailto' },
    { description: 'ИП Мурзин Максим Сергеевич' },
    { description: 'ИНН: 745800542006' },
];

