import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const SwiperContainer = styled(Swiper)`
    margin-top: 10px;
    padding: 30px;
`;

export const Container = styled.div`
    margin-top: 50px;

    h2 {
        padding-left: 20px;
    }
`;