import styled from 'styled-components';
import { COLORS } from './colors';

export const CommonContainer = styled.div`
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding-inline: clamp(10px, 7vw, 150px);
    padding-block: 40px;
`;

export const CommonSection = styled.section`

`;

export const LightSection = styled(CommonSection)`
    background: ${ COLORS.white };
`;

export const DarkSection = styled(CommonSection)`
    background: ${ COLORS.dark_gradient };
`;

export const CommonTextContainer = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    span {
        font-size: 30px;
    }
`;